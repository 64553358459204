<template>
  <b-card>
    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <b-form @submit.prevent="handleSubmit(onSubmit)" enctype="multipart/form-data">
        <b-row class="mt-1">
          <b-col cols="12" md="6" lg="6">
            <b-row>
              <b-col cols="12">
                <validation-provider #default="validationContext" name="Sort Number" rules="required">
                  <b-form-group label="Sort Number">
                    <b-form-input trim placeholder="Sort Number" v-model="dataInfo.order_number" :state="getValidationState(validationContext)"/>
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12">
                <validation-provider #default="validationContext" name="Main Language" rules="required">
                  <b-form-group label="Main Language" :state="getValidationState(validationContext)">
                    <v-select v-model="dataInfo.main_language" :options="yesNoOptions" :reduce="val => val.value" :clearable="false"/>
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12">
                <validation-provider #default="validationContext" name="Code" rules="required">
                  <b-form-group label="Code">
                    <b-form-input trim placeholder="Code" v-model="dataInfo.code" :state="getValidationState(validationContext)"/>
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12">
                <validation-provider #default="validationContext" name="Name" rules="required">
                  <b-form-group label="Name">
                    <b-form-input trim placeholder="Name" v-model="dataInfo.name" :state="getValidationState(validationContext)"/>
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12">
                <validation-provider #default="validationContext" name="Panel Status" rules="required">
                  <b-form-group label="Panel Status" :state="getValidationState(validationContext)">
                    <v-select v-model="dataInfo.panel_status" :options="statusOptions" :reduce="val => val.value" :clearable="false"/>
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12">
                <validation-provider #default="validationContext" name="WebSite Status" rules="required">
                  <b-form-group label="WebSite Status" :state="getValidationState(validationContext)">
                    <v-select v-model="dataInfo.website_status" :options="statusOptions" :reduce="val => val.value" :clearable="false"/>
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="12" md="6" lg="6">
            <div class="mt-5 d-flex justify-content-center align-content-end">
              <b-avatar
                  ref="previewEl"
                  :src="(dataInfo.imageBase64 ? dataInfo.imageBase64 : getApiFile(dataInfo.image_url))"
                  :text="avatarText(dataInfo.name)"
                  button
                  variant="primary"
                  size="70px"
                  @click="$refs.refInputEl.click()"/>
              <input
                  ref="refInputEl"
                  type="file"
                  class="d-none"
                  @input="inputImageRenderer">
            </div>
          </b-col>
        </b-row>
        <action-buttons :back-route="'cms-language-list'"/>
      </b-form>
    </validation-observer>
    <Overlay :busy="busy"></Overlay>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BFormInvalidFeedback, BAvatar,
} from 'bootstrap-vue'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {required, email} from '@validations'
import vSelect from 'vue-select'
import store from "@/store"
import storeModule from "@/views/cms/language/store"
import router from '@/router'
import Overlay from "@/components/Overlay.vue"
import ActionButtons from "@/components/Form/ActionButtons.vue"
import {useToast} from 'vue-toastification/composition'
import {avatarText, getApiFile, statusOptions, yesNoOptions} from "@core/utils/filter"
import {onUnmounted, ref} from '@vue/composition-api'
import formValidation from '@core/comp-functions/forms/form-validation'
import {toastMessage} from "@core/utils/utils"
import {useInputImageRenderer} from "@core/comp-functions/forms/form-utils"

export default {
  components: {
    BAvatar,
    BCard,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BFormInvalidFeedback,

    vSelect,
    ActionButtons,
    Overlay,

    ValidationProvider,
    ValidationObserver,
  },
  props: {},
  data() {
    return {
      required,
      email,
    }
  },
  setup() {
    const STORE_MODULE_NAME = 'store'
    if (!store.hasModule(STORE_MODULE_NAME)) {
      store.registerModule(STORE_MODULE_NAME, storeModule)
    }
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) {
        store.unregisterModule(STORE_MODULE_NAME)
      }
    })

    const toast = useToast()
    const busy = ref(false)

    const dataInfo = ref({
      id: 0,
      order_number: 0,
      code: '',
      name: '',
      main_language: false,
      image_url: '',
      imageBase64: null,
      website_status: 1,
      panel_status: 1,
    })

    const previewEl = ref(null)
    const refInputEl = ref(null)
    const {inputImageRenderer} = useInputImageRenderer(refInputEl, base64 => {
      dataInfo.value.imageBase64 = base64
    })

    const onSubmit = () => {
      busy.value = true
      store.dispatch('store/' + (router.currentRoute.params.id > 0 ? 'editItem' : 'addItem'), dataInfo.value).then(response => {
        toastMessage(toast, 'success', response.data.message)
        router.push({name: 'cms-language-list'})
      }).catch(error => {
        toastMessage(toast, 'danger', error.response.data.error_message)
      }).finally(message => {
        busy.value = false
      })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation()

    if (router.currentRoute.params.id > 0) {
      busy.value = true
      store.dispatch('store/fetchItem', {id: router.currentRoute.params.id > 0 ? router.currentRoute.params.id : 0}).then(response => {
        dataInfo.value = response.data.data
      }).catch(error => {
        toastMessage(toast, 'danger', error.response.data.message)
        if (error.response.status === 403) {
          router.push({name: 'cms-language-list'})
        }
      }).finally(message => {
        busy.value = false
      })
    }

    return {
      busy,
      dataInfo,
      previewEl,
      refInputEl,
      refFormObserver,

      statusOptions,
      yesNoOptions,

      onSubmit,
      getValidationState,
      resetForm,
      getApiFile,
      avatarText,
      inputImageRenderer,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
